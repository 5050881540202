import React from 'react'
import { IProductSearch, ProductViewType } from '@core/api/Products/types'
import { LYSCol } from '@core/components/Primitives'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'
import ProductCard from '../../shared/ProductCard'
import ProductCollectionListItem from '../ProductCollectionListItem'
import { usePrismicSettings } from '@core/prismic/components/PageLayout/PrismicSettingsContext'
import MinimizedProductCard from '@core/components/shared/MinimizedProductCard/MinimizedProductCard'

const ProductCollectionItems: React.FC = () => {
  const { productView, productCollection } = useProductCollectionContext() || {}
  const { minimized } = usePrismicSettings()

  if (!productCollection) return null

  return (
    <>
      {productCollection.hits.map((product: IProductSearch) => {
        return productView === ProductViewType.GRID ? (
          <LYSCol xs={24} sm={24} lg={12} xl={8} xxl={6} key={product.id}>
            {minimized ? (
              <MinimizedProductCard product={product} />
            ) : (
              <ProductCard product={product} />
            )}
          </LYSCol>
        ) : (
          <LYSCol xs={24} key={product.id}>
            <ProductCollectionListItem product={product} />
          </LYSCol>
        )
      })}
    </>
  )
}

export default ProductCollectionItems
