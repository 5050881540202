import * as React from 'react'
import {
  IProductsQueryOrder,
  IProductsQueryOrderValues,
} from '@core/api/Products/types'
import {
  LYSCol,
  LYSRow,
  LYSSelect,
  LYSSelectOption,
  LYSTypography,
} from '@core/components/Primitives'
import useProductCollectionQuery from '@core/components/ProductCollection/useProductCollectionQuery'
import config from '@core/config/config'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'

export const DEFAULT_VALUE = 'default_value'
export const SEPARATOR = '.'

export const encodeSortingValue = (queryOrder: IProductsQueryOrder) =>
  Object.keys(queryOrder)[0] + SEPARATOR + Object.values(queryOrder)[0]

export const decodeSortingValue = (
  encodedValue: string
): IProductsQueryOrder => {
  const splitString = encodedValue.split(SEPARATOR)
  return {
    [splitString.slice(0, -1).join(SEPARATOR)]: splitString[
      splitString.length - 1
    ] as IProductsQueryOrderValues,
  }
}

const SortingControl: React.FC = () => {
  const { t } = useTranslation()
  const { query, setOrder, removeKey } = useProductCollectionQuery()

  return (
    <LYSRow gutter={'md'} align="middle">
      <LYSCol className={style.col}>
        <LYSTypography.Text type="secondary">
          {t('product.productCollection.sortByText')}
        </LYSTypography.Text>
      </LYSCol>
      <LYSCol>
        <LYSSelect
          size="small"
          className={style.select}
          onSelect={(value: string) => {
            if (typeof value !== 'string') return

            if (value === DEFAULT_VALUE) {
              removeKey('order')
            } else {
              setOrder(decodeSortingValue(value))
            }
          }}
          value={query.order ? encodeSortingValue(query.order) : DEFAULT_VALUE}
        >
          <LYSSelectOption value={DEFAULT_VALUE}>
            {t('product.productCollection.relevanceText')}
          </LYSSelectOption>
          {config.features.newProductsFeature && (
            <LYSSelectOption value={encodeSortingValue({ created: 'desc' })}>
              {t('product.productCollection.newestProductsText')}
            </LYSSelectOption>
          )}
          <LYSSelectOption value={encodeSortingValue({ price: 'asc' })}>
            {t('product.productCollection.priceAscText')}
          </LYSSelectOption>
          <LYSSelectOption value={encodeSortingValue({ price: 'desc' })}>
            {t('product.productCollection.priceDescText')}
          </LYSSelectOption>
          <LYSSelectOption value={encodeSortingValue({ sku: 'asc' })}>
            {t('product.productCollection.itemNumberText')}
          </LYSSelectOption>
          <LYSSelectOption value={encodeSortingValue({ name: 'asc' })}>
            {t('product.productCollection.nameText')}
          </LYSSelectOption>
        </LYSSelect>
      </LYSCol>
    </LYSRow>
  )
}

export default SortingControl
