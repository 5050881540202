import React from 'react'
import { LYSRow } from '@core/components/Primitives'
import LYSLinkPagination from '@core/components/shared/LYSLinkPagination'
import { useTranslation } from '@core/i18n/i18n'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'
import ProductCollectionItems from '../ProductCollectionItems'

export interface ProductCollectionContentProps {
  paginationClassName?: string
}

export const ProductCollectionContent: React.FC<ProductCollectionContentProps> =
  ({ paginationClassName }) => {
    const { t } = useTranslation()
    const {
      productCollection,
      productCollectionProductsTotal,
      isLoading,
      hidePagination,
      query,
      setItemPerPageLimit,
    } = useProductCollectionContext() || {}

    if (isLoading || !productCollection || !query) return null

    return productCollectionProductsTotal ? (
      <>
        <LYSRow gutter="md">
          <ProductCollectionItems />

          {!hidePagination && (
            <LYSLinkPagination
              className={paginationClassName}
              total={productCollection.total}
              showTotal={(totalCount, range) =>
                t('product.productCollection.pagination', {
                  from: range[0],
                  to: range[1],
                  total: totalCount,
                })
              }
              pageSize={query.limit}
              current={query.page}
              showSizeChanger={true}
              onShowSizeChange={setItemPerPageLimit}
              locale={{
                items_per_page: t('product.productCollection.itemsPerPage'),
              }}
            />
          )}
        </LYSRow>
      </>
    ) : null
  }
